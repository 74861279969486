import { useContext } from 'react';
import { Welcome } from './sample/Welcome';
import { TeamsFxContext } from './Context';
import config from './sample/lib/config';

const showFunction = Boolean(config.apiName);

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  return (
    <div className={`tab-app ${themeString === 'default' ? 'light' : themeString === 'dark' ? 'dark' : 'contrast'}`}>
      <Welcome showFunction={showFunction} />
    </div>
  );
}
